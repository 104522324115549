<notifier-container></notifier-container>

<footer class="page-footer mdb-color center-on-small-only">
    <div class="container">
        <div class="row hidden-md-down">
            <div class="col-lg-3 hidden-md-down" >
                <img src="../../assets/logofoo.png" alt="">
            </div>
            <hr class="hidden-md-up">
            <div class="col-lg-3 hidden-md-down">
                <ul class="menu-foo">
                    <li><a routerLink="/color-gel">color gel</a></li>
                    <li><a routerLink="/catalogo-general">catálogo on</a></li>
                    <li><a routerLink="/bcl-spa">bcl spa</a></li>
                    <li><a routerLink="/donde-comprar" >dónde comprar</a></li>
                    <li><a routerLink="/donde-aprender">Cómo aprender</a></li>
                    <li><a routerLink="/educadores-on">Educadores ON</a></li>
                </ul>
            </div>
            <hr class="hidden-md-up">
            <div class="col-lg-3 hidden-md-down">
                <ul class="menu-foo">
                    <li><a routerLink="/acerca-de">acerca de</a></li>
                    <li><a routerLink="/atencion-a-clientes">Atención a Clientes </a></li>
                    <li><a routerLink="/mapadelsitio">Mapa del sitio </a></li>
                    <li><a routerLink="/seguridad-y-privacidad">Seguridad y privacidad</a></li>
                    <li><a routerLink="/contacto">contacto</a></li>
                </ul>
            </div>
            <hr class="hidden-md-up">
            <hr class="hidden-md-up esp">
            <div class="col-lg-3 espMovFoo">
                <p class="titleFoo">SÍGUENOS EN</p>
                <div class="sociales">
                    <a href="https://www.facebook.com/organicnailsoficial/" target="_blank"><img src="../../assets/footer/facebook.png"></a>
                    <a href="https://www.instagram.com/organicnails/" target="_blank"><img src="../../assets/footer/insta.png"></a>
                    <a href="https://www.pinterest.com.mx/organicnails/?autologin=true" target="_blank"><img src="../../assets/footer/pinterest.png"></a>
                </div>
                <p class="titleFoo">Boletín</p>
                <p class="textFoo">Recibe todas las noticias de Organic® Nails en tu correo:</p>
                <form accept-charset="utf-8" #formNews="ngForm" (ngSubmit)="enviarNews();">
                    <input class="form-control inpFoo" type="email" placeholder="Correo electrónico" name="email" #email="ngModel" [(ngModel)]="news.email" required email><br>
                    <div class="row">
                        <div class="col-lg-2 col-md-2">
                            <div class="round">
                                <input type="checkbox" id="checkbox" name="deacuerdo" #deacuerdo="ngModel" [(ngModel)]="news.deacuerdo" required email="true"/>
                                <label for="checkbox"></label>
                            </div>
                        </div>
                        <div class="col-lg-10 col-md-10 divText">
                            <label class="checkText">
                                Estoy de acuerdo en recibir correos de Organic® Nails
                            </label>
                        </div>
                    </div>
                    <span class="Error" *ngIf="email.touched && !email.valid">Campos faltantes</span>
                    <button type="submit" class="btn btnOrganicBlack btnFoo" [disabled]="!email.valid">Enviar</button>
                </form>
            </div>
            <div class="col-lg-3 col-md-12 hidden-md-up espMovFoo" >
                <img src="../../assets/logofoo.png" alt="">
            </div>
            <div class="col-sm-6 hidden-md-up text-left">
                <ul class="menu-foo">
                    <li><a routerLink="/color-gel">color gel</a></li>
                    <li><a routerLink="/catalogo-general">catálogo on</a></li>
                    <li><a routerLink="/bcl-spa">bcl spa</a></li>
                    <li><a routerLink="/donde-comprar" >dónde comprar</a></li>
                    <li><a routerLink="/donde-aprender">Cómo aprender</a></li>
                    <li><a routerLink="/educadores-on">Educadores ON</a></li>
                </ul>
            </div>
            <div class="col-sm-6 hidden-md-up text-left">
                <ul class="menu-foo">
                     <li><a routerLink="/acerca-de">acerca de</a></li>
                    <li><a routerLink="/atencion-a-clientes">Atención a Clientes </a></li>
                    <li><a routerLink="/mapadelsitio">Mapa del sitio </a></li>
                    <li><a routerLink="/seguridad-y-privacidad">Seguridad y privacidad</a></li>
                    <li><a routerLink="/contacto">contacto</a></li>
                </ul>
            </div>
        </div>
        <div class="row hidden-lg-up">
            <div class="col-md-12 espMovFoo">
                <p class="titleFoo">SÍGUENOS EN</p>
                <div class="sociales">
                    <a href="https://www.facebook.com/organicnailsoficial/" target="_blank"><img src="../../assets/footer/facebook.png"></a>
                    <a href="https://www.instagram.com/organicnails/" target="_blank"><img src="../../assets/footer/insta.png"></a>
                    <a href="https://www.pinterest.com.mx/organicnails/?autologin=true" target="_blank"><img src="../../assets/footer/pinterest.png"></a>
                </div>
                <p class="titleFoo">Boletín</p>
                <p class="textFoo">Recibe todas las noticias de Organic® Nails en tu correo:</p>
                <form accept-charset="utf-8" #formNews="ngForm" (ngSubmit)="enviarNews();">
                    <input class="form-control inpFoo" type="email" placeholder="Correo electrónico" name="email" #email="ngModel" [(ngModel)]="news.email" required email><br>
                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <div class="round">
                                <input type="checkbox" id="checkbox" name="deacuerdo" #deacuerdo="ngModel" [(ngModel)]="news.deacuerdo" required email="true"/>
                                <label for="checkbox"></label>
                            </div>
                            <label class="checkText">
                                Estoy de acuerdo en recibir correos de Organic® Nails
                            </label>
                        </div>
                    </div>
                    <span class="Error" *ngIf="email.touched && !email.valid">Campos faltantes</span>
                    <button type="submit" class="btn btnOrganicBlack btnFoo" [disabled]="!email.valid">Enviar</button>
                </form>
            </div>
        </div>
        <div class="row hidden-lg-up">
            <div class="col-lg-3 col-md-12 hidden-md-up espMovFoo" >
                <img src="../../assets/logofoo.png" alt="">
            </div>
        </div>
        <div class="row hidden-lg-up flex">
            <div class="linksMovil">
                <ul class="menu-foo">
                    <li><a routerLink="/color-gel">color gel</a></li>
                    <li><a routerLink="/catalogo-general">catálogo on</a></li>
                    <li><a routerLink="/bcl-spa">bcl spa</a></li>
                    <li><a routerLink="/donde-comprar" >dónde comprar</a></li>
                    <li><a routerLink="/donde-aprender">Cómo aprender</a></li>
                    <li><a routerLink="/educadores-on">Educadores ON</a></li>
                </ul>
            </div>
            <div class="linksMovil">
                <ul class="menu-foo">
                     <li><a routerLink="/acerca-de">acerca de</a></li>
                    <li><a routerLink="/atencion-a-clientes">Atención a Clientes </a></li>
                    <li><a routerLink="/mapadelsitio">Mapa del sitio </a></li>
                    <li><a routerLink="/seguridad-y-privacidad">Seguridad y privacidad</a></li>
                    <li><a routerLink="/contacto">contacto</a></li>
                </ul>
            </div>
        </div>
    </div>
    <!--<div class="footer-copyright text-center">
        <div class="container-fluid">
            © 2017 Copyright |

        </div>
    </div>-->
</footer>
