<nav class="navbar navbar-expand-lg scrolling-navbar">
  <a class="navbar-brand" routerLink="/"
    ><img class="logo" src="../../assets/logoWhite.png" alt=""
  /></a>
  <button class="btnSearchMovile" (click)="changeSearch()" *ngIf="searchIcon">
    <i class="fas fa-search"></i>
  </button>
  <label class="search movilSearch">
    <input
      *ngIf="searchInput"
      type="text"
      class="form-control buscar"
      placeholder="BUSCAR"
      name="searchInpit"
      [(ngModel)]="search"
      (keyup)="onEnter($event)"
    />
  </label>
  <button
    class="navbar-toggler collapsed"
    type="button"
    data-toggle="collapse"
    data-target="#navbarSupportedContent"
    aria-controls="navbarSupportedContent"
    aria-expanded="false"
    aria-label="Toggle navigation"
    (click)="openMenu()"
  >
    <i class="fas fa-bars"></i>
  </button>
  <div
    class="navbar-collapse collapse"
    id="navbarSupportedContent"
    *ngIf="showMenu"
  >
    <div class="container-fluid hidden-md-down">
      <div class="row">
        <div class="col-lg-7 offset-lg-5 col-md-12 topbar">
          <ul class="navbar-nav">
            <li class="nav-item">
              <a
                class="nav-link waves-effect waves-light"
                routerLink="/acerca-de"
                routerLinkActive="active"
                >acerca de</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link waves-effect waves-light"
                routerLink="/atencion-a-clientes"
                routerLinkActive="active"
                >atención a clientes</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link waves-effect waves-light"
                routerLink="/contacto"
                routerLinkActive="active"
                >contacto</a
              >
            </li>
          </ul>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-5 offset-lg-7 col-md-12 topbar search">
          <label class="search">
            <input
              type="text"
              class="form-control buscar"
              placeholder="BUSCAR"
              name="searchInpit"
              [(ngModel)]="search"
              (keyup)="onEnter($event)"
            />
          </label>
        </div>
      </div>
      <div class="row espMenu">
        <div class="col-lg-12 col-md-12">
          <ul class="navbar-nav">
            <li class="nav-item dropdown" dropdown>
              <a
                href="#"
                id="navbarDropdownMenuLink"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="true"
                class="nav-link waves-effect waves-light dropdown-toggle active"
                routerLinkActive="active"
                *ngIf="
                  url == 'catalogo-general' ||
                  url == 'bcl-spa' ||
                  url == 'color-gel' ||
                  url == 'tech-gel'
                "
              >
                líneas de productos <i class="fas fa-angle-down"></i>
              </a>
              <a
                href="#"
                id="navbarDropdownMenuLink"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="true"
                class="nav-link waves-effect waves-light dropdown-toggle"
                routerLinkActive="active"
                *ngIf="
                  url != 'catalogo-general' &&
                  url != 'bcl-spa' &&
                  url != 'color-gel' &&
                  url != 'tech-gel'
                "
              >
                lineas de productos <i class="fas fa-angle-down"></i>
              </a>
              <div
                class="dropdown-menu dropdown-primary dropdown-menu-right"
                aria-labelledby="navbarDropdownMenuLink"
                role="menu"
              >
                <a class="dropdown-item" routerLink="/catalogo-general"
                  >Catálogo general</a
                >
                <a class="dropdown-item" routerLink="/color-gel">Color gel</a>
                <a class="dropdown-item" routerLink="/bcl-spa">bcl spa</a>
                <a class="dropdown-item" routerLink="/tech-gel">Tech gel</a>
              </div>
            </li>
            <li class="nav-item dropdown" dropdown>
              <a
                href="#"
                id="navbarDropdownMenuLink"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="true"
                class="nav-link waves-effect waves-light dropdown-toggle active"
                routerLinkActive="active"
                *ngIf="
                  url == 'donde-comprar' ||
                  url == 'otros-paises' ||
                  url == 'nacional'
                "
              >
                puntos de venta <i class="fas fa-angle-down"></i>
              </a>
              <a
                href="#"
                id="navbarDropdownMenuLink"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="true"
                class="nav-link waves-effect waves-light dropdown-toggle"
                routerLinkActive="active"
                *ngIf="
                  url != 'donde-comprar' &&
                  url != 'otros-paises' &&
                  url != 'nacional'
                "
              >
                puntos de venta <i class="fas fa-angle-down"></i>
              </a>
              <div
                class="dropdown-menu dropdown-primary dropdown-menu-right"
                aria-labelledby="navbarDropdownMenuLink"
                role="menu"
              >
                <a class="dropdown-item" routerLink="/mexico">México</a>
                <a class="dropdown-item" routerLink="/colombia">Colombia</a>
                <a class="dropdown-item" routerLink="/otros-paises"
                  >otros paises</a
                >
              </div>
            </li>
            <li class="nav-item">
              <a
                class="nav-link waves-effect waves-light active"
                routerLink="/donde-aprender"
                routerLinkActive="active"
                *ngIf="
                  url == 'donde-aprender' ||
                  url == 'como-aprender' ||
                  url == 'eventos'
                "
              >
                Dónde aprender
              </a>
              <a
                class="nav-link waves-effect waves-light"
                routerLink="/donde-aprender"
                routerLinkActive="active"
                *ngIf="
                  url != 'donde-aprender' &&
                  url != 'como-aprender' &&
                  url != 'eventos'
                "
              >
                Dónde aprender
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link waves-effect waves-light active"
                routerLink="/educadores-on"
                routerLinkActive="active"
                *ngIf="url == 'educadores-on' || url == 'promaster'"
              >
                educadores
              </a>
              <a
                class="nav-link waves-effect waves-light"
                routerLink="/educadores-on"
                routerLinkActive="active"
                *ngIf="url != 'educadores-on' && url != 'promaster'"
              >
                educadores
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link waves-effect waves-light"
                href="http://organicnailscatalogo.com/"
                target="_blank"
              >
                CATÁLOGO DIGITAL
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="container-fluid hidden-lg-up">
      <div class="row espMenu">
        <div class="col-lg-9 offset-lg-1 col-md-12">
          <ul class="navbar-nav">
            <li class="nav-item dropdown" dropdown>
              <a
                href="#"
                id="navbarDropdownMenuLink"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="true"
                class="nav-link waves-effect waves-light dropdown-toggle active"
                routerLinkActive="active"
                style="background-color: rgba(0, 0, 0, 0.03); color: #000"
                *ngIf="
                  url == 'catalogo-general' ||
                  url == 'bcl-spa' ||
                  url == 'color-gel' ||
                  url == 'tech-gel'
                "
              >
                líneas de productos
              </a>
              <a
                href="#"
                id="navbarDropdownMenuLink"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="true"
                class="nav-link waves-effect waves-light dropdown-toggle"
                routerLinkActive="active"
                style="background-color: rgba(0, 0, 0, 0.03); color: #000"
                *ngIf="
                  url != 'catalogo-general' &&
                  url != 'bcl-spa' &&
                  url != 'color-gel' &&
                  url != 'tech-gel'
                "
              >
                lineas de productos
              </a>
              <div
                class="dropdown-menu dropdown-primary dropdown-menu-right"
                aria-labelledby="navbarDropdownMenuLink"
                role="menu"
              >
                <a class="dropdown-item" routerLink="/catalogo-general"
                  >Catálogo general</a
                >
                <a class="dropdown-item" routerLink="/color-gel">Color gel</a>
                <a class="dropdown-item" routerLink="/bcl-spa">bcl spa</a>
                <a class="dropdown-item" routerLink="/tech-gel">Tech gel</a>
              </div>
            </li>
            <li class="nav-item dropdown" dropdown style="margin-top: 40px">
              <a
                href="#"
                id="navbarDropdownMenuLink"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="true"
                class="nav-link waves-effect waves-light dropdown-toggle active"
                routerLinkActive="active"
                style="
                  background-color: rgba(0, 0, 0, 0.03);
                  color: #000 !important;
                "
                *ngIf="
                  url == 'donde-comprar' ||
                  url == 'otros-paises' ||
                  url == 'nacional'
                "
              >
                puntos de venta
              </a>
              <a
                href="#"
                id="navbarDropdownMenuLink"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="true"
                class="nav-link waves-effect waves-light dropdown-toggle"
                routerLinkActive="active"
                style="
                  background-color: rgba(0, 0, 0, 0.03);
                  color: #000 !important;
                "
                *ngIf="
                  url != 'donde-comprar' &&
                  url != 'otros-paises' &&
                  url != 'nacional'
                "
              >
                puntos de venta
              </a>
              <div
                class="dropdown-menu dropdown-primary dropdown-menu-right"
                aria-labelledby="navbarDropdownMenuLink"
                role="menu"
              >
                <a class="dropdown-item" routerLink="/mexico">México</a>
                <a class="dropdown-item" routerLink="/colombia">Colombia</a>
                <a class="dropdown-item" routerLink="/otros-paises"
                  >otros paises</a
                >
              </div>
            </li>
            <li class="nav-item" style="margin-top: 40px">
              <a
                class="nav-link waves-effect waves-light active"
                routerLink="/donde-aprender"
                routerLinkActive="active"
                *ngIf="
                  url == 'donde-aprender' ||
                  url == 'como-aprender' ||
                  url == 'eventos'
                "
              >
                cómo aprender
              </a>
              <a
                class="nav-link waves-effect waves-light"
                routerLink="/donde-aprender"
                routerLinkActive="active"
                *ngIf="
                  url != 'donde-aprender' &&
                  url != 'como-aprender' &&
                  url != 'eventos'
                "
              >
                cómo aprender
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link waves-effect waves-light active"
                routerLink="/educadores-on"
                routerLinkActive="active"
                *ngIf="url == 'educadores-on' || url == 'promaster'"
              >
                educadores on
              </a>
              <a
                class="nav-link waves-effect waves-light"
                routerLink="/educadores-on"
                routerLinkActive="active"
                *ngIf="url != 'educadores-on' && url != 'promaster'"
              >
                educadores on
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4 offset-lg-8 col-md-12 topbar">
          <ul class="navbar-nav">
            <li class="nav-item">
              <a
                class="nav-link waves-effect waves-light"
                routerLink="/acerca-de"
                routerLinkActive="active"
                >acerca de</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link waves-effect waves-light"
                routerLink="/atencion-a-clientes"
                routerLinkActive="active"
                >atención a clientes</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link waves-effect waves-light"
                routerLink="/contacto"
                routerLinkActive="active"
                >contacto</a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</nav>
