import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'acerca-de',
    loadChildren: () =>
      import('./aboutus/aboutus.module').then((m) => m.AboutusModule),
  },
  {
    path: 'atencion-a-clientes',
    loadChildren: () =>
      import('./customer-service/customer-service.module').then(
        (m) => m.CustomerServiceModule
      ),
  },
  {
    path: 'contacto',
    loadChildren: () =>
      import('./contact/contact.module').then((m) => m.ContactModule),
  },
  {
    path: 'mexico',
    loadChildren: () =>
      import('./national/national.module').then((m) => m.NationalModule),
  },
  {
    path: 'colombia',
    loadChildren: () =>
      import('./national/national.module').then((m) => m.NationalModule),
  },
  {
    path: 'educadores-on',
    loadChildren: () =>
      import('./educators/educators.module').then((m) => m.EducatorsModule),
  },
  {
    path: 'promaster/:page',
    loadChildren: () =>
      import('./promaster/promaster.module').then((m) => m.PromasterModule),
  },
  {
    path: 'donde-aprender',
    loadChildren: () =>
      import('./where-to-learn/where-to-learn.module').then(
        (m) => m.WhereToLearnModule
      ),
  },
  {
    path: 'como-aprender',
    loadChildren: () =>
      import('./how-to-learn/how-to-learn.module').then(
        (m) => m.HowToLearnModule
      ),
  },
  {
    path: 'otros-paises',
    loadChildren: () =>
      import('./other-countries/other-countries.module').then(
        (m) => m.OtherCountriesModule
      ),
  },
  {
    path: 'tech-gel',
    loadChildren: () =>
      import('./techgel/techgel.module').then((m) => m.TechgelModule),
  },
  {
    path: 'bcl-spa',
    loadChildren: () =>
      import('./bclspa/bclspa.module').then((m) => m.BclspaModule),
  },
  {
    path: 'color-gel',
    loadChildren: () =>
      import('./colorgel/colorgel.module').then((m) => m.ColorgelModule),
  },
  {
    path: 'catalogo-general',
    loadChildren: () =>
      import('./general-catalog/general-catalog.module').then(
        (m) => m.GeneralCatalogModule
      ),
  },
  {
    path: 'categoria/:page',
    loadChildren: () =>
      import('./category/category.module').then((m) => m.CategoryModule),
  },
  {
    path: 'producto/:page',
    loadChildren: () =>
      import('./product/product.module').then((m) => m.ProductModule),
  },
  {
    path: 'donde-comprar',
    loadChildren: () =>
      import('./where-to-buy/where-to-buy.module').then(
        (m) => m.WhereToBuyModule
      ),
  },
  {
    path: 'seguridad-y-privacidad',
    loadChildren: () =>
      import('./notice-of-privacy/notice-of-privacy.module').then(
        (m) => m.NoticeOfPrivacyModule
      ),
  },
  {
    path: 'mapadelsitio',
    loadChildren: () =>
      import('./site-map/site-map.module').then((m) => m.SiteMapModule),
  },
  {
    path: 'buscador/:slug',
    loadChildren: () =>
      import('./search/search.module').then((m) => m.SearchModule),
  },
  {
    path: 'cecon',
    loadChildren: () =>
      import('./cecon/cecon.module').then((m) => m.CeconModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
