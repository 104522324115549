import { Component, OnInit } from '@angular/core';
import { NotifierService } from 'angular-notifier';

import { NewsletterService } from '../../services/newsletter.service';

import { Newsletter } from '../../models/newsletter';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  providers: [NewsletterService],
})
export class FooterComponent implements OnInit {
  public patternEmail =
    '/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$/';
  public news: Newsletter;
  public banderaDisabled = true;
  public bandMensaje: boolean;
  public mensajeNewsletter;
  public hideShowAnimator: boolean;
  private readonly notifier: NotifierService;

  constructor(
    private newsletterService: NewsletterService,
    notifierService: NotifierService
  ) {
    this.news = new Newsletter('', '', '', '', true, '');
    this.notifier = notifierService;
    this.hideShowAnimator = true;
  }

  ngOnInit(): void {}

  enviarNews() {
    this.hideShowAnimator = true;
    this.newsletterService.save(this.news).subscribe(
      (result) => {
        if (result) {
          this.notifier.notify(
            'success',
            '¡Exito! La operación se realizo exitosamente'
          );
          this.resetNewsletter();
        } else {
          this.notifier.notify(
            'error',
            '¡Error! La operación no se realizo exitosamente'
          );
          this.resetNewsletter();
        }
      },
      (error) => {
        this.notifier.notify(
          'error',
          '¡Error! La operación no se realizo exitosamente'
        );
        const errorMessage = error as any;
        console.log(errorMessage);
      }
    );
  }

  resetNewsletter() {
    this.news = new Newsletter('', '', '', '', true, '');
  }
}
