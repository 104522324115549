import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  public w = window.innerWidth;
  public h = window.innerHeight;
  public showMenu;
  public showSubmenu = false;
  public aparecer = 'none';
  public icono = 'fa-bars';
  public base = 'https://organicnails.mx';
  public url;
  public search;
  public searchIcon: boolean;
  public searchInput: boolean;

  constructor(private router: Router) {
    this.searchInput = false;
    this.searchIcon = true;
  }

  ngOnInit() {
    if (this.w > 992) {
      this.showMenu = true;
    } else {
      this.showMenu = false;
    }
    const aux = window.location.href;
    const auxsplit = aux.split('/');
    this.url = auxsplit[3];
  }

  @HostListener('window:resize', ['$event'])
  public resizeWindow(event: Event): void {
    this.w = window.innerWidth;
    this.h = window.innerHeight;
    if (this.w > 992) {
      this.showMenu = true;
    } else {
      this.showMenu = false;
    }
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(event) {
    const nav = document.getElementsByClassName('navbar');
    const scroll = event.srcElement.children[0].scrollTop;
    if (scroll > 48) {
      for (let i = 0; i < nav.length; i++) {
        const slide = nav[i] as HTMLElement;
        slide.classList.add('fixed-top');
      }
    } else {
      for (let i = 0; i < nav.length; i++) {
        const slide = nav[i] as HTMLElement;
        slide.classList.remove('fixed-top');
      }
    }
  }

  @HostListener('document:click', ['$event'])
  documentClick(event: MouseEvent) {
    const click: any = event.target;
    console.log(click);
    if (
      click.matches('path') ||
      click.matches('svg') ||
      click.matches('.navbar-toggler')
    ) {} else {
      this.openMenu();
    }
  }

  icon() {
    if (this.icono === 'fa-bars') {
      this.icono = 'fa-times';
    } else {
      this.icono = 'fa-bars';
    }
  }

  changeSearch() {
    this.searchIcon = !this.searchIcon;
    this.searchInput = !this.searchInput;
  }

  onEnter(event: KeyboardEvent) {
    if (event.code === 'Enter') {
      window.location.pathname = '/buscador/' + this.search;
    }
  }

  closeMenuMovil() {}

  openMenu() {
    this.showMenu = !this.showMenu;
  }
}
